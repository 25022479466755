import pluralize from 'pluralize';
import React, { useEffect } from 'react';

import { MediaFormatVersion } from '@lp-lib/media';

import placeholder from '../../../assets/img/placeholder/game-cover.png';
import { GameActionType } from '../../../types/game';
import { type GameActionNotification } from '../../../types/notification';
import { assertExhaustive } from '../../../utils/common';
import { MediaUtils } from '../../../utils/media';
import { useGameLikeWorkspace } from '../../Game/GameCenter';
import { useGameEditorStore } from '../../Game/GameEditorStore';
import { XIcon } from '../../icons/XIcon';
import { useNotificationDataSource } from '../Context';
import { type BaseNotificationProps } from '../type';

function titleForAction(
  actionType: GameActionType,
  numOfBlocks: number
): string {
  switch (actionType) {
    case GameActionType.CreateGame:
      return 'Created a new Minigame';
    case GameActionType.AddAllBlocks:
      return 'Added entire Minigame to';
    case GameActionType.AddBlocks:
      return `Added ${numOfBlocks} ${pluralize('block', numOfBlocks)} to`;
    case GameActionType.PublishGame:
      return 'Published to Minigame Library';
    case GameActionType.CopyPrimeGame:
      return 'Added to My Minigames';
    default:
      assertExhaustive(actionType);
      return 'Unknown';
  }
}

const GameActionNotifcationView = (
  props: BaseNotificationProps & {
    notification: GameActionNotification;
  }
): JSX.Element => {
  const store = useGameEditorStore();
  const metadata = props.notification.metadata;
  const actionType = metadata.actionType;
  const numOfBlocks = metadata.numOfBlocks || 0;
  const game = metadata.game;
  const coverUrl =
    MediaUtils.PickMediaUrl(game.cover, {
      priority: [MediaFormatVersion.SM],
    }) || placeholder;
  const notificationDataSource = useNotificationDataSource();
  const [, setActiveGame] = useGameLikeWorkspace('game', 'active');

  useEffect(() => {
    if (coverUrl) {
      const img = new Image();
      img.src = coverUrl;
    }
  }, [coverUrl]);

  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    notificationDataSource.dismiss(props.notification.id);
  };

  const handleClick = async () => {
    if (actionType === GameActionType.PublishGame) {
      setActiveGame(game);
    } else {
      await store.setEditingGame(game);
      notificationDataSource.dismiss(props.notification.id);
    }
  };
  return (
    <div
      className='w-full flex items-center justify-center cursor-pointer group bg-secondary'
      onClick={handleClick}
    >
      <div className='w-26 h-15 bg-black'>
        <img
          className='w-full h-full object-cover'
          src={coverUrl}
          alt='cover'
        />
      </div>
      <div className='flex flex-col ml-3 w-65'>
        <div className='text-2xs text-[#C9C9C9]'>
          {titleForAction(actionType, numOfBlocks)}:
        </div>
        <div className='text-sm text-white truncate group-hover:underline'>
          {game.name}
        </div>
      </div>
      <div className='cursor-pointer ml-auto mr-4' onClick={handleClose}>
        <XIcon />
      </div>
    </div>
  );
};

// Required for lazy import
// eslint-disable-next-line import/no-default-export
export default GameActionNotifcationView;
